<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">未结算款项总览</span>
        <span class="title_right" style="color: #000; font-size: 16px">单位：元</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'QuantityYear'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            v-model="sectionInfo.quantityYear"
            @change="onQuantityYearChange"
        /></span>
      </div>
      <div class="project_individual" v-for="item in quantityList" :key="item.name">
        <div class="project_individual_title">
          {{ item.name }}
        </div>
        <div class="project_individual_center">{{ item.value | applyAmount }}</div>
      </div></el-card
    >
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">年度经营数据</span>
        <span class="title_right"
          ><SearchUserDate
            :title="'统计季度：'"
            :type="'yearStatistics'"
            :introductionType="'quarter'"
            :clearable="true"
            :filterable="true"
            :list="quarterGenerate"
            :yearValue="sectionInfo.yearStatisticsYear"
            v-model="sectionInfo.quarter"
            @change="onYearStatisticsChange"
            @onChangeLoading="yearStatisticsLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'yearStatistics'"
            :introductionType="'quarter'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.quarter"
            v-model="sectionInfo.yearStatisticsYear"
            @change="onYearStatisticsChange"
            @onChangeLoading="yearStatisticsLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onYearManageImg"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onYearManageExporting"
          >导出</el-button
        >
        <el-radio-group
          v-model="currentView"
          size="small"
          class="title_right"
          @change="handleViewChange"
        >
          <el-radio-button v-for="view in presetViews" :key="view.name" :label="view.name">
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>
        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in columnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="handleCheckAllChange(group)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group v-model="checkedColumns" @change="handleColumnChange">
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
        <!-- 更新人工成本 -->
        <el-button type="primary" class="title_right" size="small" @click="onUpdateActualCost"
          >更新人工成本</el-button
        >
      </div>
      <el-table
        ref="yearStatisticsTable"
        :key="tableKey"
        :summary-method="getSummaries"
        v-loading="yearStatisticsLoading"
        show-summary
        max-height="550"
        border
        :data="yearStatisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>

        <template v-for="col in visibleColumns">
          <el-table-column :key="col.prop" v-bind="col">
            <template slot="header" slot-scope="{}">
              <span>{{ col.label }}</span>
              <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px" />
                <div slot="content" v-html="col.tooltip"></div>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <template v-if="col.dict">
                {{ scope.row[col.prop] | dict(dictData[col.dict]) }}
              </template>
              <template v-else-if="col.prop === 'actualExpenditure'">
                <el-tooltip
                  effect="light"
                  placement="top"
                  popper-class="actual-expenditure-tooltip"
                >
                  <div slot="content" v-if="scope.row.actualExpenditureDataVo">
                    <div class="cost-breakdown">
                      <div class="cost-item">
                        <span class="cost-label">商务费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.businessCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">项目费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.projectCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">公共费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.publicCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">实际人工成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.laborCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">其它成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.otherCost | applyAmount
                        }}</span>
                      </div>
                      <!-- <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">采购实际付款金额</span>
                        <span class="cost-value">{{ scope.row.payments | applyAmount }}</span>
                      </div> -->
                      <div class="operator">=</div>
                      <div class="cost-item total">
                        <span class="cost-label">实际支出</span>
                        <span class="cost-value">{{
                          (scope.row.actualExpenditureDataVo.businessCost +
                            scope.row.actualExpenditureDataVo.projectCost +
                            scope.row.actualExpenditureDataVo.publicCost +
                            scope.row.actualExpenditureDataVo.laborCost +
                            scope.row.actualExpenditureDataVo.otherCost)
                            | applyAmount
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <a style="color: blue; text-decoration: underline" href="javascript:void(0)">
                    {{ scope.row.actualExpenditure | applyAmount }}
                  </a>
                </el-tooltip>
              </template>
              <template v-else-if="col.isAmount">
                {{ scope.row[col.prop] | applyAmount }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">月度财务数据</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'monthStatistics'"
            :introductionType="'month'"
            :clearable="true"
            :filterable="true"
            :list="monthGenerate"
            :yearValue="sectionInfo.monthStatisticsYear"
            v-model="sectionInfo.monthStatisticsMonth"
            @change="onMonthStatisticsChange"
            @onChangeLoading="monthStatisticsLoading = $event"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :type="'monthStatistics'"
            :introductionType="'month'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.monthStatisticsMonth"
            v-model="sectionInfo.monthStatisticsYear"
            @change="onMonthStatisticsChange"
            @onChangeLoading="monthStatisticsLoading = $event"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onMonthFinanceImg"
          >数据来源说明</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-printer"
          class="title_right"
          :loading="exportingLoading"
          size="small"
          @click="onMonthFinanceExporting"
          >导出</el-button
        >
        <el-radio-group
          v-model="currentMonthView"
          size="small"
          class="title_right"
          @change="handleMonthViewChange"
        >
          <el-radio-button v-for="view in monthPresetViews" :key="view.name" :label="view.name">
            {{ view.name }}
          </el-radio-button>
        </el-radio-group>
        <el-popover placement="bottom" width="300" trigger="click" class="title_right">
          <div class="column-setting-container">
            <div class="column-groups">
              <div v-for="group in monthColumnGroups" :key="group.name" class="column-group">
                <div class="group-title">
                  <el-checkbox
                    :indeterminate="group.indeterminate"
                    v-model="group.checkAll"
                    @change="val => handleMonthCheckAllChange(group, val)"
                  >
                    {{ group.label }}
                  </el-checkbox>
                </div>
                <div class="group-items">
                  <el-checkbox-group
                    v-model="checkedMonthColumns"
                    @change="handleMonthColumnChange"
                  >
                    <el-checkbox v-for="col in group.columns" :key="col.prop" :label="col.prop">
                      {{ col.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
            <div class="column-setting-footer">
              <el-button size="small" @click="resetMonthColumns">重置</el-button>
            </div>
          </div>
          <el-button slot="reference" size="small">
            <i class="el-icon-setting"></i> 列设置
          </el-button>
        </el-popover>
      </div>
      <el-table
        ref="monthStatisticsTable"
        :key="monthTableKey"
        :summary-method="getSummariesMonth"
        v-loading="monthStatisticsLoading"
        show-summary
        max-height="550"
        border
        :data="monthStatisticsList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>

        <template v-for="col in visibleMonthColumns">
          <el-table-column :key="col.prop" v-bind="col">
            <template slot="header" slot-scope="{}">
              <span>{{ col.label }}</span>
              <el-tooltip v-if="col.tooltip" class="item" effect="dark" placement="top">
                <i class="el-icon-question" style="font-size: 16px" />
                <div slot="content" v-html="col.tooltip"></div>
              </el-tooltip>
            </template>

            <template slot-scope="scope">
              <template v-if="col.dict">
                {{ scope.row[col.prop] | dict(dictData[col.dict]) }}
              </template>
              <template v-else-if="col.prop === 'actualExpenditure'">
                <el-tooltip
                  effect="light"
                  placement="top"
                  popper-class="actual-expenditure-tooltip"
                >
                  <div slot="content" v-if="scope.row.actualExpenditureDataVo">
                    <div class="cost-breakdown">
                      <div class="cost-item">
                        <span class="cost-label">商务费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.businessCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">项目费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.projectCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">公共费用</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.publicCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">实际人工成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.laborCost | applyAmount
                        }}</span>
                      </div>
                      <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">其它成本</span>
                        <span class="cost-value">{{
                          scope.row.actualExpenditureDataVo.otherCost | applyAmount
                        }}</span>
                      </div>
                      <!-- <div class="operator">+</div>
                      <div class="cost-item">
                        <span class="cost-label">采购实际付款金额</span>
                        <span class="cost-value">{{ scope.row.payments | applyAmount }}</span>
                      </div> -->
                      <div class="operator">=</div>
                      <div class="cost-item total">
                        <span class="cost-label">实际支出</span>
                        <span class="cost-value">{{
                          (scope.row.actualExpenditureDataVo.businessCost +
                            scope.row.actualExpenditureDataVo.projectCost +
                            scope.row.actualExpenditureDataVo.publicCost +
                            scope.row.actualExpenditureDataVo.laborCost +
                            scope.row.actualExpenditureDataVo.otherCost)
                            | applyAmount
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <a style="color: blue; text-decoration: underline" href="javascript:void(0)">
                    {{ scope.row.actualExpenditure | applyAmount }}
                  </a>
                </el-tooltip>
              </template>
              <template v-else-if="col.isAmount">
                {{ scope.row[col.prop] | applyAmount }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          v-show="imgUrl == '财务统计-年度经营数据'"
          src="@/assets/财务统计-年度经营数据.png"
          alt="数据来源说明"
          width="100%"
        />
        <img
          v-show="imgUrl == '财务统计-公司月度财务数据'"
          src="@/assets/财务统计-公司月度财务数据.png"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
    <templateUpload
      title="更新人工成本"
      template-name="更新人工成本模版.xlsx"
      :uploadApi="$api.laborCost.addBatchLaborCostManage"
      :uploadHandler="updateActualCostHandler"
      ref="templateUpload"
    />
  </div>
</template>

<script>
// 首先定义列配置常量
const COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    {
      prop: 'company',
      label: '公司',
      width: '100',
      fixed: 'left',
      align: 'center',
      dict: 'companyType',
    },
    {
      prop: 'projectCount',
      label: '项目数量',
      width: '100',
      sortable: true,
      align: 'center',
      tooltip: '今年所实施的项目数量',
    },
  ],
  // 销售信息列
  amount: [
    {
      prop: 'salesRevenue',
      label: '销售合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额（销售合同）',
    },
    {
      prop: 'invoicedAmount',
      label: '开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已开票金额（销售合同）',
    },
    {
      prop: 'receivedAmount',
      label: '回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已收款金额（销售合同）',
    },
  ],
  // 采购信息列
  purchase: [
    {
      prop: 'procurement',
      label: '采购合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额带出（采购合同）',
    },
    {
      prop: 'receivedInvoices',
      label: '采购发票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已开票金额（采购合同）',
    },
    {
      prop: 'payments',
      label: '采购实际付款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已收款金额（采购合同）',
    },
  ],
  // 报销信息列
  reimbursement: [
    {
      prop: 'businessExpend',
      label: '商务报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用报销汇总',
    },
    {
      prop: 'projectExpend',
      label: '项目报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目费用报销汇总',
    },
    {
      prop: 'publicSpendingExpend',
      label: '公共报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公共费用报销汇总',
    },
    {
      prop: 'reimbursements',
      label: '总报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务报销 + 项目报销 + 公共报销',
    },
  ],
  // income: [
  // ],
  // 成本信息列
  cost: [
    {
      prop: 'laborCost',
      label: '实际人工成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'projectManhourCost',
      label: '项目工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的项目工时成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'businessManhourCost',
      label: '组织工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的管理工时合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'hourCost',
      label: '工时成本合计',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目工时成本 + 组织工时成本',
    },
  ],
  // // 支出信息列
  // expense: [
  // ],
  // 利润信息列
  profit: [
    {
      prop: 'actualReceipts',
      label: '实际收款',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目收入中收款金额',
    },
    {
      prop: 'actualExpenditure',
      label: '实际支出',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用 + 项目费用 + 公共费用 + 实际人工成本 + 税费  + 设备折旧 ',
    },
    {
      prop: 'fundBalance',
      label: '现金流',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '实际收款 - 实际支出',
    },
    // {
    //   prop: 'totalRevenue',
    //   label: '总收入',
    //   minWidth: '130',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'totalCost',
    //   label: '总成本',
    //   minWidth: '130',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
    // {
    //   prop: 'totalProfit',
    //   label: '总利润',
    //   minWidth: '130',
    //   sortable: true,
    //   align: 'right',
    //   isAmount: true,
    // },
  ],
}

// 首先添加月度数据的列定义常量
const MONTH_COLUMN_DEFINITIONS = {
  // 基本信息列
  basic: [
    {
      prop: 'company',
      label: '公司',
      width: '100',
      fixed: 'left',
      align: 'center',
      dict: 'companyType',
    },
  ],
  // 销售相关列
  sales: [
    {
      prop: 'salesRevenue',
      label: '销售合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额（销售合同）',
    },
    {
      prop: 'receivedAmount',
      label: '回款金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已收款金额',
    },
    {
      prop: 'invoicedAmount',
      label: '开票金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务所创建的项目，项目收入中的已开票金额',
    },
  ],
  // 采购相关列
  purchase: [
    {
      prop: 'procurement',
      label: '采购合同金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目详情里的合同实际金额带出（采购合同）',
    },
  ],
  // 报销相关列
  reimbursement: [
    {
      prop: 'businessExpend',
      label: '商务报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用报销汇总',
    },
    {
      prop: 'projectExpend',
      label: '项目报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目费用报销汇总',
    },
    {
      prop: 'publicSpendingExpend',
      label: '公共报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公共费用报销汇总',
    },
    {
      prop: 'reimbursements',
      label: '总报销',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务报销 + 项目报销 + 公共报销',
    },
    {
      prop: 'receivedInvoices',
      label: '应付金额',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '按发起时间算起的所有报销 + 付款申请金额',
    },
  ],
  // income: [
  // ],
  // 成本相关列
  cost: [
    {
      prop: 'laborCost',
      label: '实际人工成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'projectManhourCost',
      label: '项目工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的项目工时成本合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'businessManhourCost',
      label: '组织工时成本',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '公司人员的管理工时合计，<br/>截至上周的累计人工',
    },
    {
      prop: 'hourCost',
      label: '工时成本合计',
      minWidth: '130',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '项目工时成本 + 管理工时成本',
    },
  ],
  expense: [
    {
      prop: 'actualExpenditure',
      label: '实际支出',
      minWidth: '150',
      sortable: true,
      align: 'right',
      isAmount: true,
      tooltip: '商务费用 + 项目费用 + 公共费用 + 实际人工成本 + 税费  + 设备折旧 ',
    },
  ],
}

export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    templateUpload: () => import('@/components/upload/TemplateUpload.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
      },
      quantityList: [], // 数量统计
      yearStatisticsList: [], // 年度经营数据
      monthStatisticsList: [], // 月度财务数据
      sectionInfo: {
        // 条件筛选
        quantityYear: null, // 年份
        yearStatisticsYear: null, // 年份
        monthStatisticsYear: null,
        monthStatisticsMonth: null,
        quarter: '',
      },
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
      projectPaymentAmount: 0,
      projectPaymentAmountC: 0,
      projectPaymentAmountMnth: 0,
      projectPaymentAmountCMnth: 0,
      columnGroups: [
        {
          name: 'basic',
          label: '基本信息',
          columns: COLUMN_DEFINITIONS.basic.slice(1), // 不包含公司列
        },
        {
          name: 'amount',
          label: '销售信息',
          columns: COLUMN_DEFINITIONS.amount,
        },
        {
          name: 'purchase',
          label: '采购信息',
          columns: COLUMN_DEFINITIONS.purchase,
        },
        {
          name: 'reimbursement',
          label: '报销信息',
          columns: COLUMN_DEFINITIONS.reimbursement,
        },
        {
          name: 'cost',
          label: '成本信息',
          columns: COLUMN_DEFINITIONS.cost,
        },
        // {
        //   name: 'income',
        //   label: '收入信息',
        //   columns: COLUMN_DEFINITIONS.income,
        // },
        // {
        //   name: 'expense',
        //   label: '支出信息',
        //   columns: COLUMN_DEFINITIONS.expense,
        // },
        {
          name: 'profit',
          label: '利润信息',
          columns: COLUMN_DEFINITIONS.profit,
        },
      ],
      checkedColumns: [], // 默认显示的列
      currentView: '全部',
      presetViews: [
        {
          name: '销售信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.amount],
        },
        {
          name: '采购信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.purchase],
        },
        {
          name: '报销信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.reimbursement],
        },
        {
          name: '成本信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.cost],
        },
        {
          name: '利润信息',
          getColumns: () => [...COLUMN_DEFINITIONS.basic, ...COLUMN_DEFINITIONS.profit],
        },
        {
          name: '全部',
          getColumns: () => Object.values(COLUMN_DEFINITIONS).flat(),
        },
      ],
      tableKey: 0, // 用于强制刷新表格
      monthColumnGroups: [
        {
          name: 'basic',
          label: '基本信息',
          columns: MONTH_COLUMN_DEFINITIONS.basic,
        },
        {
          name: 'sales',
          label: '销售信息',
          columns: MONTH_COLUMN_DEFINITIONS.sales,
        },
        {
          name: 'purchase',
          label: '采购信息',
          columns: MONTH_COLUMN_DEFINITIONS.purchase,
        },
        {
          name: 'reimbursement',
          label: '报销信息',
          columns: MONTH_COLUMN_DEFINITIONS.reimbursement,
        },
        // {
        //   name: 'income',
        //   label: '收入信息',
        //   columns: MONTH_COLUMN_DEFINITIONS.income,
        // },
        {
          name: 'cost',
          label: '成本信息',
          columns: MONTH_COLUMN_DEFINITIONS.cost,
        },
        {
          name: 'expense',
          label: '支出信息',
          columns: MONTH_COLUMN_DEFINITIONS.expense,
        },
      ],
      checkedMonthColumns: [], // 选中的月度表格列
      currentMonthView: '全部', // 当前月度视图
      monthPresetViews: [
        {
          name: '销售信息',
          getColumns: () => [...MONTH_COLUMN_DEFINITIONS.basic, ...MONTH_COLUMN_DEFINITIONS.sales],
        },
        {
          name: '采购信息',
          getColumns: () => [
            ...MONTH_COLUMN_DEFINITIONS.basic,
            ...MONTH_COLUMN_DEFINITIONS.purchase,
          ],
        },
        {
          name: '报销信息',
          getColumns: () => [
            ...MONTH_COLUMN_DEFINITIONS.basic,
            ...MONTH_COLUMN_DEFINITIONS.reimbursement,
          ],
        },
        {
          name: '成本信息',
          getColumns: () => [...MONTH_COLUMN_DEFINITIONS.basic, ...MONTH_COLUMN_DEFINITIONS.cost],
        },
        {
          name: '全部',
          getColumns: () => Object.values(MONTH_COLUMN_DEFINITIONS).flat(),
        },
      ],
      monthTableKey: 0, // 用于强制刷新月度表格
      yearStatisticsLoading: false,
      monthStatisticsLoading: false,
      exportingLoading: false,
    }
  },
  provide() {
    return {}
  },
  computed: {
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
    visibleColumns() {
      const currentView = this.presetViews.find(v => v.name === this.currentView)
      if (!currentView) return []

      if (this.currentView === '全部') {
        // 公司列始终显示
        const alwaysShowColumns = COLUMN_DEFINITIONS.basic.slice(0, 1)

        // 其他基本信息列根据选择显示
        const otherBasicColumns = COLUMN_DEFINITIONS.basic
          .slice(1)
          .filter(col => this.checkedColumns.includes(col.prop))

        // 构建最终的列数组
        const resultColumns = [...alwaysShowColumns, ...otherBasicColumns]

        // 添加其他组的列
        const otherGroups = ['amount', 'purchase', 'reimbursement', 'cost', 'profit']
        otherGroups.forEach(groupName => {
          const groupColumns = COLUMN_DEFINITIONS[groupName].filter(col =>
            this.checkedColumns.includes(col.prop)
          )
          resultColumns.push(...groupColumns)
        })

        return resultColumns
      }

      return currentView.getColumns()
    },
    visibleMonthColumns() {
      const currentView = this.monthPresetViews.find(v => v.name === this.currentMonthView)
      if (!currentView) return []

      if (this.currentMonthView === '全部') {
        // 公司列始终显示
        const alwaysShowColumns = MONTH_COLUMN_DEFINITIONS.basic.slice(0, 1)

        // 构建最终的列数组
        const resultColumns = [...alwaysShowColumns]

        // 添加其他组的列
        const otherGroups = ['sales', 'purchase', 'reimbursement', 'cost', 'expense']
        otherGroups.forEach(groupName => {
          const groupColumns = MONTH_COLUMN_DEFINITIONS[groupName].filter(col =>
            this.checkedMonthColumns.includes(col.prop)
          )
          resultColumns.push(...groupColumns)
        })

        return resultColumns
      }

      return currentView.getColumns()
    },
  },
  created() {
    this.getType()
    // 初始化列配置
    this.checkedColumns = this.columnGroups.flatMap(group => group.columns).map(col => col.prop)

    this.updateGroupCheckStatus()

    // 从localStorage读取用户的列显示偏好
    // const savedColumns = localStorage.getItem('yearStatistics-columns')
    // if (savedColumns) {
    //   this.checkedColumns = JSON.parse(savedColumns)
    //   this.updateGroupCheckStatus()
    // }

    // 初始化月度表格列配置
    this.checkedMonthColumns = this.monthColumnGroups
      .flatMap(group => group.columns)
      .map(col => col.prop)

    this.updateMonthGroupCheckStatus()
    // 从localStorage读取月度表格的列显示偏好
    // const savedMonthColumns = localStorage.getItem('monthStatistics-columns')
    // if (savedMonthColumns) {
    //   this.checkedMonthColumns = JSON.parse(savedMonthColumns)
    //   this.updateMonthGroupCheckStatus()
    // }
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    updateActualCostHandler() {
      this.getType()
    },
    onUpdateActualCost() {
      this.$refs.templateUpload.showDialog()
    },
    // 年度
    async onYearManageExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.yearStatisticsYear }
      if (this.sectionInfo.quarter) {
        obj.quarter = this.sectionInfo.quarter
      }
      try {
        const res = await this.$api.statistics.financialDashExecutiveData(obj)
        const fileName = '年度经营数据.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 月度
    async onMonthFinanceExporting() {
      this.exportingLoading = true
      const obj = { date: this.sectionInfo.monthStatisticsYear }
      if (this.sectionInfo.monthStatisticsMonth) {
        obj.month = this.sectionInfo.monthStatisticsMonth
      }
      try {
        const res = await this.$api.statistics.financialDashFinanceData(obj)
        const fileName = '月度财务数据.xlsx'
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        this.downloadFile(blob, fileName)
        this.$message.success('导出成功')
      } catch (err) {
        console.error('导出失败:', err)
        this.$message.error('导出失败，请重试')
      } finally {
        this.exportingLoading = false
      }
    },
    // 提取出下载文件的逻辑
    downloadFile(blob, fileName) {
      if (navigator.msSaveBlob) {
        // 针对 IE
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      }
    },
    onYearManageImg() {
      this.imgUrl = '财务统计-年度经营数据'
      this.isDialog = true
    },
    onMonthFinanceImg() {
      this.imgUrl = '财务统计-公司月度财务数据'
      this.isDialog = true
    },
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.financialBalancesData(`date=${val}`),
        this.$api.statistics.financiaAnnualExecutiveData(`date=${val}`),
        this.$api.statistics.financiaMonthlyFinanceReport(`date=${val}`),
      ])
        .then(res => {
          const { financialDataVo = {} } = res[0].data
          const keyMappings = {
            unpaidInvoice: '开票未回款',
            unpaidNationalInvoice: '回款未开票',
            unpaidReceivedInvoice: '收票未付款',
            unreceivedPayment: '付款未收票',
            unreturnedBidDeposit: '未退投标保证金',
            overdueBidDeposit: '超期未退投标保证金',
            unreturnedPerformanceDeposit: '未退履约保证金',
            overduePerformanceDeposit: '超期未退履约保证金',
            unreturnedDeposit: '未退押金',
            overdueDeposit: '超期未退押金',
            unpaidLoan: '未还借款',
          }
          this.quantityList = Object.keys(financialDataVo)
            .filter(key => keyMappings.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: keyMappings[key], // 根据键获取名称
              value: financialDataVo[key],
            }))

          this.yearStatisticsList =
            res[1].data.annualExecutiveDataView.annualExecutiveDataVoList || []
          this.yearStatisticsList.forEach(v => {
            v.hourCost = v.projectManhourCost + v.businessManhourCost
          })
          this.projectPaymentAmount = res[1].data.annualExecutiveDataView.projectPaymentAmount || 0
          this.projectPaymentAmountC =
            res[1].data.annualExecutiveDataView.projectPaymentAmountC || 0
          this.monthStatisticsList =
            res[2].data.monthlyFinanceDataView.monthlyFinanceDataVoList || []
          this.monthStatisticsList.forEach(v => {
            v.hourCost = v.projectManhourCost + v.businessManhourCost
          })
          this.projectPaymentAmountMnth =
            res[2].data.monthlyFinanceDataView.projectPaymentAmount || 0
          this.projectPaymentAmountCMnth =
            res[2].data.monthlyFinanceDataView.projectPaymentAmountC || 0
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onMonthStatisticsChange(obj) {
      this.monthStatisticsList = obj.monthlyFinanceDataVoList || []
      this.monthStatisticsList.forEach(v => {
        v.hourCost = v.projectManhourCost + v.businessManhourCost
      })
      this.projectPaymentAmountMnth = obj.projectPaymentAmount || 0
      this.projectPaymentAmountCMnth = obj.projectPaymentAmountC || 0
    },
    onYearStatisticsChange(obj) {
      this.yearStatisticsList = obj.annualExecutiveDataVoList || []
      this.yearStatisticsList.forEach(v => {
        v.hourCost = v.projectManhourCost + v.businessManhourCost
      })
      this.projectPaymentAmount = obj.projectPaymentAmount || 0
      this.projectPaymentAmountC = obj.projectPaymentAmountC || 0
    },
    onQuantityYearChange(arr) {
      this.quantityList = arr
    },

    // 表格合计
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        // 获取列的属性名
        const property = column.property

        // 如果是特殊处理的列
        if (property === 'salesRevenue') {
          sums[index] = Number(this.projectPaymentAmount.toFixed(2)).toLocaleString()
          return
        }
        if (property === 'procurement') {
          sums[index] = Number(this.projectPaymentAmountC.toFixed(2)).toLocaleString()
          return
        }

        // 其他数值列的合计
        const values = data.map(item => Number(item[property]))
        if (!values.every(value => isNaN(value))) {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            }
            return prev
          }, 0)
          sums[index] = Number(sum.toFixed(2)).toLocaleString()
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    getSummariesMonth(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        // 获取列的属性名
        const property = column.property

        // 如果是特殊处理的列
        if (property === 'salesRevenue') {
          sums[index] = Number(this.projectPaymentAmountMnth.toFixed(2)).toLocaleString()
          return
        }
        if (property === 'procurement') {
          sums[index] = Number(this.projectPaymentAmountCMnth.toFixed(2)).toLocaleString()
          return
        }

        // 其他数值列的合计
        const values = data.map(item => Number(item[property]))
        if (!values.every(value => isNaN(value))) {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            }
            return prev
          }, 0)
          sums[index] = Number(sum.toFixed(2)).toLocaleString()
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.quantityYear = dictVal
          this.sectionInfo.yearStatisticsYear = dictVal
          this.sectionInfo.monthStatisticsYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('yearStatistics-columns')
        this.checkedColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.columnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateGroupCheckStatus()

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    handleCheckAllChange(group) {
      const groupColumns = group.columns.map(col => col.prop)
      if (group.checkAll) {
        this.checkedColumns = [...new Set([...this.checkedColumns, ...groupColumns])]
      } else {
        this.checkedColumns = this.checkedColumns.filter(col => !groupColumns.includes(col))
      }
      this.handleColumnChange(this.checkedColumns)
    },

    handleColumnChange(value) {
      this.currentView = '全部'
      this.checkedColumns = value
      this.updateGroupCheckStatus()
      localStorage.setItem('yearStatistics-columns', JSON.stringify(value))

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    updateGroupCheckStatus() {
      this.columnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col => this.checkedColumns.includes(col)).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },

    resetColumns() {
      this.currentView = '全部'
      this.checkedColumns = this.getDefaultColumns()
      this.updateGroupCheckStatus()
      localStorage.setItem('yearStatistics-columns', JSON.stringify(this.checkedColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshTable()
      })
    },

    getDefaultColumns() {
      return this.columnGroups.flatMap(group => group.columns).map(col => col.prop)
    },

    refreshTable() {
      if (this.$refs.yearStatisticsTable) {
        this.$refs.yearStatisticsTable.doLayout()
      }
    },
    handleMonthViewChange(viewName) {
      if (viewName === '全部') {
        const savedColumns = localStorage.getItem('monthStatistics-columns')
        this.checkedMonthColumns = savedColumns
          ? JSON.parse(savedColumns)
          : this.monthColumnGroups.flatMap(group => group.columns.map(col => col.prop))
      }
      this.updateMonthGroupCheckStatus()

      // 使用 nextTick 确保 DOM 更新后再重新计算表格
      this.$nextTick(() => {
        this.refreshMonthTable()
      })
    },

    handleMonthCheckAllChange(group, val) {
      const groupColumns = group.columns.map(col => col.prop)
      if (val) {
        this.checkedMonthColumns = [...new Set([...this.checkedMonthColumns, ...groupColumns])]
      } else {
        this.checkedMonthColumns = this.checkedMonthColumns.filter(
          col => !groupColumns.includes(col)
        )
      }
      this.handleMonthColumnChange(this.checkedMonthColumns)
    },

    handleMonthColumnChange(value) {
      this.currentMonthView = '全部'
      this.checkedMonthColumns = value
      this.updateMonthGroupCheckStatus()
      localStorage.setItem('monthStatistics-columns', JSON.stringify(value))

      this.$nextTick(() => {
        this.refreshMonthTable()
      })
    },

    updateMonthGroupCheckStatus() {
      this.monthColumnGroups.forEach(group => {
        const groupColumns = group.columns.map(col => col.prop)
        const checkedCount = groupColumns.filter(col =>
          this.checkedMonthColumns.includes(col)
        ).length
        group.checkAll = checkedCount === group.columns.length
        group.indeterminate = checkedCount > 0 && checkedCount < group.columns.length
      })
    },

    resetMonthColumns() {
      this.currentMonthView = '全部'
      this.checkedMonthColumns = this.getDefaultMonthColumns()
      this.updateMonthGroupCheckStatus()
      localStorage.setItem('monthStatistics-columns', JSON.stringify(this.checkedMonthColumns))

      this.$message({
        type: 'success',
        message: '已重置为默认显示',
      })

      this.$nextTick(() => {
        this.refreshMonthTable()
      })
    },

    getDefaultMonthColumns() {
      return this.monthColumnGroups.flatMap(group => group.columns).map(col => col.prop)
    },

    refreshMonthTable() {
      this.monthTableKey++
      if (this.$refs.monthStatisticsTable) {
        this.$refs.monthStatisticsTable.doLayout()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.project_individual {
  display: inline-block;
  text-align: center;
  width: 190px;
  font-size: 18px;
  .project_individual_title {
    font-size: 15px;
  }
  .project_individual_center {
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 900;
    color: #5c5b87;
  }
}
.text_Details_Bgc {
  //详情
  background-color: #409eff;
  color: #fff;
  padding: 5px 5px;
}
/deep/.el-card__header {
  border-bottom: 0px;
}
/deep/.el-tabs {
  .el-tabs__item {
    color: #000;
  }
}
.column-setting-container {
  .column-groups {
    max-height: 400px;
    overflow-y: auto;
  }

  .column-group {
    margin-bottom: 16px;

    .group-title {
      margin-bottom: 8px;
    }

    .group-items {
      padding-left: 24px;

      .el-checkbox {
        display: block;
        margin-left: 0;
        margin-bottom: 8px;
      }
    }
  }

  .column-setting-footer {
    text-align: center;
    margin-top: 16px;
    border-top: 1px solid #ebeef5;
    padding-top: 16px;
  }
}

.actual-expenditure-tooltip {
  .el-tooltip__popper {
    max-width: none !important;
    padding: 16px 20px !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
    background: #fff !important;
    border: none !important;
  }

  .cost-breakdown {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background: linear-gradient(to right, rgba(64, 158, 255, 0.05), rgba(64, 158, 255, 0.1));
    padding: 12px 16px;
    border-radius: 6px;

    .cost-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 120px;
      padding: 8px 12px;
      border-radius: 4px;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
      }

      .cost-label {
        color: #606266;
        font-size: 13px;
        margin-bottom: 6px;
        font-weight: 500;
      }

      .cost-value {
        color: #303133;
        font-size: 15px;
        font-weight: 600;
        font-family: Monaco, monospace;
      }

      &.total {
        background: rgba(64, 158, 255, 0.1);
        border: 1px dashed #409eff;

        &:hover {
          background: rgba(64, 158, 255, 0.15);
        }

        .cost-label {
          color: #409eff;
        }
        .cost-value {
          color: #409eff;
          font-weight: 700;
        }
      }
    }

    .operator {
      margin: 0 8px;
      color: #909399;
      font-size: 18px;
      font-weight: bold;
      padding-top: 10px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
